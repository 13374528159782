<template>
  <div class='row'>
    <div class='col-lg-6 col-12'>
      <div class='card card-top card-top-primary'>
        <div class='card-header'>
          <vb-headers-card-header :data="{ title: 'Yeni Antrenör Ekle' }" />
        </div>

        <div class='card-body'>

          <image-resizer
            :loading='loading'
            :on-complete='beforeUpload'
            :max-size='904'
            make-square
            required
            :error-text='"Fotoğraf en az 900x900 piksel ölçülerinde kare olmalıdır. (*)"'
          />
          <a-form
            label-align='left'
            layout='vertical'
          >
            <div class='row mt-2'>
              <div class='col-12 col-sm'>
                <a-form-item label='Adı (*)' name='name'>
                  <a-input v-model:value='newPtName' placeholder='Adı' />
                </a-form-item>
                <a-form-item label='Soyadı (*)' name='surname'>
                  <a-input v-model:value='newPtSurname' placeholder='Soyadı' />
                </a-form-item>
                <a-form-item label='Hesap Şifresi (*)' name='password'>
                  <a-input-password v-model:value='newPtPassword' placeholder='Hesap Şifresi' />
                  <a-tag color='error' v-if='newPtPassword !== "" && (newPtPassword.length < 6 || newPtPassword.length > 64)'
                         class='mt-1'>
                    <template #icon>
                      <i class='fe fe-alert-circle' />
                    </template>
                    Minimum 6, Maximum 64 Karakter olmalıdır
                  </a-tag>
                </a-form-item>
                <a-form-item label='Doğum Tarihi (*)' name='birthdate'>
                  <a-locale-provider :locale='tr_TR'>
                    <a-date-picker
                      :default-picker-value='moment("2000-01-01",dateFormat)'
                      :show-today='false'
                      v-model:value='newPtBirthDate'
                      class='col-12'
                      :format='dateFormat'
                      :disabled-date='disabledDate'
                    />
                  </a-locale-provider>
                </a-form-item>
                <a-form-item label='Mail Adresi (*)' name='email' @keypress='validateEmail(newPtMail)'>
                  <a-input v-model:value='newPtMail' placeholder='Mail Adresi' />
                  <a-tag color='error' v-if='newPtMail !== "" & !validateEmail(newPtMail)' class='mt-1'>
                    <template #icon>
                      <i class='fe fe-alert-circle' />
                    </template>
                    Geçerli Bir Mail Adresi Giriniz
                  </a-tag>
                </a-form-item>
                <a-form-item label='Sıralaması' name='email'>
                  <a-input v-maska.raw="'########'" v-model:value='newPtOrder' placeholder='Sıralaması' />
                </a-form-item>
              </div>
              <div class='col-12 col-sm'>
                <a-form-item label='Telefon Numarası (*)' name='phoneNumber'>
                  <a-input v-maska.raw="'(###) ###-##-##'" @maska='onMaska' v-model='newPtPhoneNumber' placeholder='Telefon Numarası' />
                  <a-tag color='error'
                         v-if='newPtPhoneNumber !== "" && (newPtPhoneNumber.length !== 10)'
                         class='mt-1'>
                    <template #icon>
                      <i class='fe fe-alert-circle' />
                    </template>
                    Geçerli Bir Telefon Numarası Giriniz
                  </a-tag>
                </a-form-item>
                <a-form-item label='Boyu (cm) (*)' name='height'>
                  <a-input v-model:value='newPtHeight' v-maska='"###"' placeholder='Boyu' />
                  <a-tag color='error' v-if='parseInt(newPtHeight) < 100 || parseInt(newPtHeight) > 300' class='mt-1'>
                    <template #icon>
                      <i class='fe fe-alert-circle' />
                    </template>
                    Minimum 100CM, Maximum 300CM olmalıdır
                  </a-tag>
                </a-form-item>
                <a-form-item label='Kilosu (kg) (*)' name='weight'>
                  <a-input v-model:value='newPtWeight' v-maska='"###"' placeholder='Kilosu' />
                  <a-tag color='error' v-if='parseInt(newPtWeight) < 30 || parseInt(newPtWeight) > 300' class='mt-1'>
                    <template #icon>
                      <i class='fe fe-alert-circle' />
                    </template>
                    Minimum 30KG, Maximum 300KG olmalıdır
                  </a-tag>
                </a-form-item>
                <a-form-item label='Hakkında' name='description'>
                  <a-textarea v-model:value='newPtDescription' :maxlength='255' :rows='4' show-count placeholder='Hakkında' />
                </a-form-item>

                <a-form-item label='Abonelik Komisyon Oranı (*)' name='comissionRate'>
                  <a-input addon-before='%' v-model:value='newPtCommissionRate' v-maska='"###"' placeholder='Komisyon Oranı' />
                  <a-tag color='error' v-if='newPtCommissionRate === "" || parseInt(newPtCommissionRate) < 0 || parseInt(newPtCommissionRate) > 100'
                         class='mt-1'>
                    <template #icon>
                      <i class='fe fe-alert-circle' />
                    </template>
                    Minimum 0%, Maximum 100% olmalıdır
                  </a-tag>
                </a-form-item>

                <a-form-item label='Ders Paketi Komisyon Oranı (*)' name='packageCourseComissionRate'>
                  <a-input addon-before='%' v-model:value='newPtPackageCourseCommissionRate' v-maska='"###"' placeholder='Ders Paketi Komisyon Oranı' />
                  <a-tag color='error'
                         v-if='newPtPackageCourseCommissionRate === "" || parseInt(newPtPackageCourseCommissionRate) < 0 || parseInt(newPtPackageCourseCommissionRate) > 100'
                         class='mt-1'>
                    <template #icon>
                      <i class='fe fe-alert-circle' />
                    </template>
                    Minimum 0%, Maximum 100% olmalıdır
                  </a-tag>
                </a-form-item>
              </div>
            </div>
          </a-form>

          <a-button key='submit' class='btn btn-success px-5' :loading='loading' @click='handleNewPtForm'
                    :disabled='!validateNewPTForm'>
            Kaydet
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { roundHalf } from '@/utils/number'
import { notification } from 'ant-design-vue'
import { validateEmail } from '../../utils/email'
import { PersonalTrainerAdministration } from '../../services/api-service'

import moment from 'moment'
import trLocale from 'moment/locale/tr'
import locale from 'ant-design-vue/es/date-picker/locale/tr_TR'
import tr_TR from 'ant-design-vue/es/locale-provider/tr_TR'
import { useRouter } from 'vue-router'

moment.updateLocale('tr', trLocale)

export default {
  name: 'YeniAntrenorEkle',
  data() {
    const router = useRouter()

    const disabledDate = (currentMoment) => {
      return !currentMoment.isBetween(moment().add(-99, 'years'), moment().add(-18, 'years'), 'seconds')
    }

    tr_TR.DatePicker.lang.ok = 'Tamam'
    return {
      router,
      loading: false,
      tr_TR,
      locale,
      moment,
      roundHalf,
      dateFormat: 'YYYY/MM/DD',
      monthFormat: 'YYYY/MM',
      disabledDate,
      validateEmail,

      newPtAvatarBase64: '',
      newPtName: '',
      newPtSurname: '',
      newPtCommissionRate: '0',
      newPtPackageCourseCommissionRate: '0',
      newPtMail: '',
      newPtHeight: '',
      newPtWeight: '',
      newPtPhoneNumber: '',
      newPtDescription: '',
      newPtPassword: '',
      newPtOrder: '0',
      newPtBirthDate: moment('2000-01-01', 'YYYY/MM/DD'),
    }
  },
  computed: {
    validateNewPTForm() {
      return (
        this.validateEmail(this.newPtMail) &&
        this.newPtName !== '' &&
        this.newPtSurname !== '' &&
        this.newPtBirthDate !== '' &&
        (parseInt(this.newPtHeight) >= 100 && parseInt(this.newPtHeight) <= 300) &&
        (parseInt(this.newPtWeight) >= 30 && parseInt(this.newPtWeight) <= 300) &&
        (parseInt(this.newPtCommissionRate) >= 0 && parseInt(this.newPtCommissionRate) <= 100) &&
        (parseInt(this.newPtPackageCourseCommissionRate) >= 0 && parseInt(this.newPtPackageCourseCommissionRate) <= 100) &&
        (this.newPtPassword.length >= 6 && this.newPtPassword.length <= 64) &&
        (this.newPtPhoneNumber.length === 10) &&
        this.newPtAvatarBase64 !== ''
      )
    },
  },
  methods: {
    beforeUpload(file) {
      this.newPtAvatarBase64 = file
    },
    onMaska: function(event) {
      this.newPtPhoneNumber = event.target.dataset.maskRawValue
    },
    async handleNewPtForm() {
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        await PersonalTrainerAdministration.createPersonalTrainer({
          avatar: this.newPtAvatarBase64,
          birthDate: moment(this.newPtBirthDate).format('YYYY-MM-DD'),
          name: this.newPtName,
          surname: this.newPtSurname,
          commissionRate: parseFloat(this.newPtCommissionRate),
          packageCourseCommissionRate: parseFloat(this.newPtPackageCourseCommissionRate),
          email: this.newPtMail,
          height: parseInt(this.newPtHeight),
          weight: parseInt(this.newPtWeight),
          phoneNumber: this.newPtPhoneNumber,
          description: this.newPtDescription,
          password: this.newPtPassword,
          ptOrder: parseInt(this.newPtOrder),
        })

        notification.success({
          message: 'Kullanıcı Başarıyla Eklendi!',
        })

        await this.router.push({ path: '/antrenorler' })

      } catch {
        this.loading = false
      }
    },
  },
}
</script>
